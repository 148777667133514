<template>
  <div class="section-container">
    <v-overlay :value="dataLoading" z-index="50">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-container fluid>
      <v-row class="signin" justify="center">
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="mx-auto">
              <div class="video-wrapper">
                <video
                  ref="player"
                  height="320"
                  width="327"
                  autoplay
                  v-show="type == undefined"
                ></video>
                <canvas
                  ref="canvas"
                  width="327"
                  height="250"
                  v-show="type == undefined"
                ></canvas>
                <v-tooltip bottom class="mr-2">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      fab
                      @click="captureImage"
                      class="captureImage"
                      v-show="showVideo"
                    >
                      <v-icon>fa fa-camera</v-icon>
                    </v-btn>
                  </template>
                  <span>Take Photo</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" justify="center">
              <div class="white--text text-center mb-5" v-if="pdf.active">
                {{ pdf.name }}
              </div>
              <div
                class="d-flex justify-center"
                v-if="type !== undefined && receipt.imgByte"
              >
                <v-img
                  :src="'data:image/png;base64,' + this.receipt.imgByte"
                  :max-width="500"
                  :max-height="500"
                  contain
                ></v-img>
              </div>
              <div class="text-center d-block" v-if="type === undefined">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      fab
                      class="mr-2"
                      @click="launchCamera"
                    >
                      <v-icon>fa fa-play</v-icon>
                    </v-btn>
                  </template>
                  <span>Launch Camera</span>
                </v-tooltip>

                <v-tooltip bottom class="mr-2">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      rounded
                      v-bind="attrs"
                      v-on="on"
                      @click="uploadImage"
                      class="mr-2"
                      ><v-icon>fa fa-file</v-icon></v-btn
                    >
                  </template>
                  <span>Browse Receipt</span>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    @change="onFileChanged"
                  />
                </v-tooltip>

                <v-tooltip bottom class="mr-2">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      rounded
                      fab
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      @click="stopCamera"
                    >
                      <v-icon color="red">fa fa-stop</v-icon>
                    </v-btn>
                  </template>
                  <span>Stop the Camera</span>
                </v-tooltip>
                <!-- <v-tooltip bottom class="mr-2">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      rounded
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      :disabled="videoDevicesList.length === 1"
                      @click="swapCamera"
                    >
                      <v-icon color="#000">fa fa-sync</v-icon>
                    </v-btn>
                  </template>
                  <span>Swap Camera</span>
                </v-tooltip> -->
                <v-tooltip bottom class="mr-2">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      rounded
                      v-bind="attrs"
                      v-on="on"
                      @click="resetCanvas"
                    >
                      <v-icon color="#000">fa fa-exchange</v-icon>
                    </v-btn>
                  </template>
                  <span>Reset Image</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" class="right">
          <div class="inner mr-sm-auto">
            <h2 class="mb-8">Receipt Scanner</h2>
            <validation-observer
              ref="receiptObserver"
              v-slot="{ handleSubmit }"
            >
              <v-form
                @submit.prevent="handleSubmit(submitReceipt)"
                ref="receiptForm"
              >
                <v-row>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Category"
                      rules="required"
                    >
                      <v-select
                        v-model="receipt.categoryID"
                        :items="categories"
                        item-text="categoryName"
                        item-value="id"
                        append-icon="fa-caret-down"
                        outlined
                        :error-messages="errors"
                        :hide-details="!errors.length"
                        class="form-control"
                        placeholder="Select Category"
                        required
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Event"
                      rules="required"
                    >
                      <v-select
                        v-model="receipt.eventID"
                        :error-messages="errors"
                        :hide-details="!errors.length"
                        item-text="eventName"
                        item-value="id"
                        append-icon="fa-caret-down"
                        :items="events"
                        outlined
                        class="form-control"
                        placeholder="Select Event"
                        required
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Amount"
                      rules="required|decimal:2"
                    >
                      <v-text-field
                        v-model="receipt.amountInt"
                        :error-messages="errors"
                        :hide-details="!errors.length"
                        class="form-control"
                        placeholder="Enter Amount"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="4 Digit of CC"
                      rules="required|numeric|digits:4"
                    >
                      <v-text-field
                        v-model="receipt.last4DigitofCC"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="Enter 4 Digit of CC"
                        required
                        outlined
                        dark
                        :hide-details="!errors.length"
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Card"
                      rules="required"
                    >
                      <v-select
                        v-model="receipt.cardTypeId"
                        class="form-control"
                        :items="cards"
                        item-text="cardTypeName"
                        item-value="id"
                        :error-messages="errors"
                        placeholder="Select Card"
                        required
                        outlined
                        dark
                        :hide-details="!errors.length"
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="receipt.receiptDate"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="receipt.receiptDate"
                          label="Receipt Date"
                          hide-details
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="receipt.receiptDate"
                        next-icon="fa-arrow-right"
                        prev-icon="fa-arrow-left"
                        :max="new Date().toISOString()"
                        no-title
                        scrollable
                        locale="en-US"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(receipt.receiptDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Notes"
                      rules="max:100"
                    >
                      <v-textarea
                        v-model="receipt.notes"
                        class="form-control"
                        :items="cards"
                        item-text="cardTypeName"
                        item-value="id"
                        :error-messages="errors"
                        placeholder="Notes"
                        required
                        outlined
                        dark
                        auto-grow
                        :hide-details="!errors.length"
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>

                <div class="text-center">
                  <v-btn
                    class="signin-btn mt-5"
                    type="submit"
                    color="primary"
                    dark
                    rounded
                    :disabled="loading"
                    :loading="loading"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { isCanvasBlank, dataURItoBlob } from "@/utils/helpers";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState } from "vuex";
import ReceiptService from "@/services/ReceiptService";
import HomeService from "@/services/HomeService";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  middleware: "auth",
  data() {
    return {
      videoDevicesList: [],
      activeCameraIndex: 0,
      menu: false,
      modal: false,
      showVideo: false,
      isShowing: false,
      receipt: {
        receiptDate: new Date().toISOString().substr(0, 10),
      },
      loading: false,
      cards: [],
      pdf: {
        active: false,
        name: null,
        file: null,
      },
      actualFile: null,
      dataLoading: false,
      type: this.$route.params.id,
    };
  },
  async created() {
    HomeService.getUserCards(this.$store.state.auth.user.userId)
      .then((res) => {
        this.cards = res.data.data;
      })
      .catch((error) => {
        this.$store.dispatch("notifications/setNotification", {
          type: "error",
          text: error.response.data.errorMessage,
          status: error.response.status,
        });
      });
    if (this.categories.length === 0 || this.events.length === 0) {
      await this.$store.dispatch("receipt/FETCH_COMMON_DATA");
    }

    if (this.$route.params.id !== undefined) {
      this.getReceipt();
    }
  },
  computed: {
    ...mapState({
      categories: (state) => {
        return state.receipt.categories.filter((category) => category.active);
      },
      events: (state) => {
        return state.receipt.events.filter((event) => event.active);
      },
    }),
  },
  head() {
    return {
      title: "Capture Snapshot",
      meta: [
        {
          hid: "description",
          name: "description",
          content: "Where You can capture images",
        },
      ],
    };
  },
  async mounted() {
    // await navigator.mediaDevices.enumerateDevices().then((data) => {
    //   this.videoDevicesList = data.filter(
    //     (device) => device.kind === "videoinput"
    //   );
    //   console.log(this.videoDevicesList);
    // });
    // if (
    //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   )
    // ) {
    //   this.activeCameraIndex = this.videoDevicesList.length === 1 ? 0 : 1;
    // }
  },
  methods: {
    getReceipt() {
      this.dataLoading = true;
      ReceiptService.fetchReceiptById(this.$route.params.id)
        .then((res) => {
          if (res.data.errorMessage === null) {
            this.receipt = Object.assign(
              {},
              {
                ...res.data.data,
                receiptDate: res.data.data.receiptDate.substr(0, 10),
                amountInt: String(res.data.data.amountInt),
                last4DigitofCC: String(res.data.data.last4DigitofCC),
              }
            );
          } else {
            this.$store.dispatch("notifications/setNotification", {
              type: "error",
              text: res.data.errorMessage,
            });
            this.$router.push("/receipt-scanner");
          }
          this.dataLoading = false;
        })
        .catch((error) => {
          this.dataLoading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
    async stopCamera() {
      this.showVideo = false;
      const player = this.$refs.player;
      // Stop all video streams.
      if (player.srcObject) {
        await player.srcObject
          .getVideoTracks()
          .forEach((track) => track.stop());
      }
      player.srcObject = null;
    },
    async launchCamera() {
      this.pdf = {
        active: false,
        name: null,
        file: null,
      };
      this.actualFile = null;
      this.showVideo = true;
      await this.resetCanvas();
      const player = this.$refs.player;
      // Attach the video stream to the video element and autoplay.
      const constraints = {
        video: {
          // deviceId: this.videoDevicesList[this.activeCameraIndex].deviceId,
          facingMode: "environment",
        },
      };

      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        player.srcObject = stream;
      });
    },
    // async swapCamera() {
    //   const cameraList = this.videoDevicesList.length;
    //   this.activeCameraIndex = (this.activeCameraIndex + 1) % cameraList;
    //   await this.stopCamera();
    //   this.launchCamera();
    // },
    async captureImage() {
      this.showVideo = false;
      const player = this.$refs.player;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.drawImage(player, 0, 0, canvas.width, canvas.height);
      await this.stopCamera();
    },
    async resetCanvas() {
      this.pdf = {
        active: false,
        name: null,
        file: null,
      };
      this.actualFile = null;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      await context.clearRect(0, 0, canvas.width, canvas.height);
    },
    uploadImage() {
      this.stopCamera();
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e !== undefined) {
        this.stopCamera();
        this.resetCanvas();
        if (e.target.files[0].type !== "application/pdf") {
          const canvas = this.$refs.canvas;
          const context = canvas.getContext("2d");
          const image = new Image();
          image.src = URL.createObjectURL(e.target.files[0]);
          image.onload = function () {
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
          };
          this.actualFile = e.target.files[0];
        } else {
          this.actualFile = null;
          this.pdf = {
            active: true,
            name: e.target.files[0].name,
            file: e.target.files[0],
          };
          this.$refs.uploader.value = "";
        }
      }
    },
    async submitReceipt() {
      let canvas = this.$refs.canvas;

      if (
        this.type === undefined &&
        isCanvasBlank(canvas) &&
        this.pdf.active === false
      ) {
        this.$store.dispatch("notifications/setNotification", {
          type: "error",
          text: "Please Select/Capture a Receipt",
        });
      } else {
        const formdata = new FormData();
        formdata.append("ID", this.receipt?.id || 0);
        formdata.append("CategoryID", this.receipt.categoryID);
        formdata.append("EventID", this.receipt.eventID);
        formdata.append("Amount", this.receipt.amountInt);
        formdata.append("Last4DigitofCC", this.receipt.last4DigitofCC);
        formdata.append("ReceiptDate", this.receipt.receiptDate);
        formdata.append("CardTypeId", this.receipt.cardTypeId);
        formdata.append("Notes", this.receipt?.notes || "");
        let blob = null;
        if (this.type !== undefined) {
          console.log("here");
          const base64Response = await fetch(
            `data:image/png;base64,${this.receipt.imgByte}`
          );

          blob = await base64Response.blob();
        } else if (this.pdf.active) {
          blob = this.pdf.file;
        } else if (this.actualFile !== null) {
          blob = this.actualFile;
        } else {
          const dataURL = canvas.toDataURL("image/jpeg", 1);
          blob = dataURItoBlob(dataURL);
        }
        formdata.append(
          "File",
          blob,
          `${
            this.categories.find(
              (category) => category.id === this.receipt.categoryID
            ).categoryName
          }${this.pdf.active ? ".pdf" : ".jpg"}`
        );
        this.loading = true;
        ReceiptService.createReceipt(formdata)
          .then((res) => {
            if (res.data.errorMessage === null) {
              if (this.receipt.id) {
                this.$store.dispatch("notifications/setNotification", {
                  type: "success",
                  text: "Receipt Updated Successfully",
                });
              } else {
                this.$store.dispatch("notifications/setNotification", {
                  type: "success",
                  text: "Receipt Uploaded Successfully",
                });
                this.resetForm();
              }
            } else {
              this.loading = false;
              this.$store.dispatch("notifications/setNotification", {
                type: "error",
                text: res.data.errorMessage,
              });
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.$store.dispatch("notifications/setNotification", {
              type: "error",
              text: error.response.data.errorMessage,
              status: error.response.status,
            });
          });
      }
    },
    async resetForm() {
      await this.resetCanvas();
      await this.stopCamera();
      this.$refs.receiptObserver.reset();
      this.receipt = Object({
        receiptDate: new Date().toISOString().substr(0, 10),
      });
      this.pdf = {
        active: false,
        name: null,
        file: null,
      };
      this.actualFile = null;
    },
  },
  async beforeDestroy() {
    await this.stopCamera();
  },
};
</script>
